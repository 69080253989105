




import { Vue, Component } from 'vue-property-decorator'
import {apiUpgradeAfter} from '@/api/setting'
@Component({

})
export default class UpgradeAfterIndex extends Vue {
        /** S Data **/
    msg=''

    /** E Data **/
    /** S Life Cycle **/
    created() {
        // console.log(this.$route.query.v);
        apiUpgradeAfter({
            v:this.$route.query.v
        }).then((res:any)=>{
            this.msg=res.msg
        }).catch((err:any)=>{
            console.log(err);
            
        })
        
    }
    /** E Life Cycle **/
}
